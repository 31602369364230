<template>
  <form>
    <div class="row mb-4">
      <div class="col-6">
        <div class="row">
          <div class="col">
            <h3>{{getTrans('messages.subscription_header')}}:</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3 class="text-primary font-weight-bold">{{ getCurrent ? getPlan(getCurrent.subscription_plan_id).title : getTrans('messages.no_subscription')}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 text-right">
        <base-button @click="toggleSubscription" type="primary" native-type="submit">{{showSubscription ? getTrans('messages.back') : getTrans('messages.change')}}</base-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <subscription-overview v-if="showSubscription"></subscription-overview>
      </div>
    </div>
  </form>
</template>
<script>
import {} from "element-plus";
import { mapGetters } from "vuex";
import SubscriptionOverview from "@/views/Subscription/SubscriptionOverview.vue";
export default {
  components: {"subscription-overview": SubscriptionOverview },
  data() {
    return {
      showSubscription: false,
      defaultPlan: {
        interval: "No plan",
        amount: "Free",
        monthly_tokens: "No tokens",
        token_purchase_bonus: "No bonus"
      }
    };
  },
  methods: {
    toggleSubscription(event) {
      if (event) {
        event.preventDefault();
      }
      this.showSubscription = !this.showSubscription;
    }
  },
  computed: {
    ...mapGetters("User", {
      getUser: "getUser", 
      getCurrent: "currentSubscriptionPlan"
    }),
    ...mapGetters("SubscriptionPlan", {
      getPlan: 'getPlan',
    }),
  },
  mounted() {
    
    this.$store.dispatch("SubscriptionPlan/get");
  }
};
</script>
